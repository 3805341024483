:root { 
--gray_600:#7d7d7d; 
--gray_700:#626262; 
--gray_400:#c4c4c4; 
--gray_500:#979797; 
--light_blue_300:#51eaff; 
--light_blue_100:#b7eefe; 
--light_blue_500:#00b9f1; 
--white_A700_00:#ffffff00; 
--black_900:#000000; 
--yellow_700:#f2c62a; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--indigo_800:#2b3990; 
}